import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './module/LandingPage';
import router from './module/Telstra/Router';

const MainRouter = () => (
  <Router>
    <Switch>
      <Route exact path='/telstra' component={LandingPage}/> 
      <Route path='/telstra' component={router}/>
    </Switch>
  </Router>
)

export default MainRouter;